<template>
    <div>
        
        <div class="normal-card bg-contcolor small-input-height">
            <div class="filter-area mb-md-5 mb-3">
                <div class="filter-box">
                    <v-form class="multi-col-validation">
                        <div class="d-flex justify-md-space-between align-center flex-md-nowrap flex-wrap">
                            <div class="flex-item">
                                <div class="d-flex align-center flex-md-nowrap flex-wrap">
                                    <v-select 
                                        v-model="valTitle_items_defaultSelected"
                                        dense
                                        outlined
                                        :items="valTitle_items"
                                        class="mb-md-0 mb-3"
                                        item-text="text"
                                        item-value="value"
                                        >
                                    </v-select>
                                    <div class="d-inline-block ml-sm-5 mt-md-0 mt-0 mb-md-0 mb-5 w-sm-auto w-full cluster-ckb">
                                        <input type="checkbox" id="ckbcluster" value="first_checkbox">
                                        <label for="ckbcluster">群集模式</label>
                                    </div>
                                    <!--<v-checkbox
                                        v-model="cluster_status"
                                        label="群集模式"
                                        class="ml-sm-5 mt-md-0 mt-0 mb-md-0 mb-5 w-sm-auto w-full cluster-ckb"
                                        hide-details="true"
                                    ></v-checkbox>-->
                                </div>
                            </div>
                            <div class="flex-item w-md-auto w-full">
                                <div class="d-flex align-center flex-md-nowrap flex-wrap">
                                    <!--<a href="" @click="addHeatLayer()" class="v-btn theme--light v-btn--is-elevated v-size--default primary mr-3 mb-md-0 mb-3 custom-btn">
                                        <v-icon>{{ icons.mdiMessageDraw }}</v-icon>
                                        繪製等濃度圖
                                    </a>-->
                                    <v-btn
                                        color="primary"
                                        class="mr-3 mb-md-0 mb-3 custom-btn heat-btn"
                                        :class={active:isKir} 
                                        @click="addHeatLayer()"
                                        >
                                        <v-icon>{{ icons.mdiMessageDraw }}</v-icon>
                                        繪製等濃度圖
                                    </v-btn>
                                    <v-btn
                                        color="primary"
                                        class="mr-3 mb-md-0 mb-3 custom-btn"
                                        @click="openDia_pos('pos')"
                                        >
                                        <v-icon>{{ icons.mdiMessageDraw }}</v-icon>
                                        定位
                                    </v-btn>
                                    <v-btn
                                        color="primary"
                                        class=" mb-md-0 mb-3 custom-btn"
                                        @click="openDia_pos('filt')"
                                        >
                                        <v-icon>{{ icons.mdiFilter }}</v-icon>
                                        篩選工具
                                    </v-btn>
                                </div>
                            </div>
                        </div>
                    </v-form>
                </div>
            </div>
            <div class="map-area">
                <div class="map-box address-mapbox">
                    <div class="searchmaparr-area">
                        <div class="d-flex align-center flex-nowrap">
                            <div class="flex-item">
                                <v-text-field
                                    v-model="inline_address_search"
                                    @keyup.enter="searchByAddress()"
                                    outlined
                                    dense
                                    placeholder="地址搜尋"
                                    hide-details
                                ></v-text-field>
                            </div>
                            <div class="flex-item">
                                <v-btn
                                    color="primary"
                                    class=" custom-btn"
                                    @click="searchByAddress()"
                                    >
                                    搜尋
                                </v-btn>
                            </div>
                        </div>
                    </div>

                    <div class="leaflet-area pos-relative full-height">
                        <div id="leafletmap"></div>
                    </div>
                </div>
                <div class="map-info">
                    <div class="close-btn-area">
                        <a href="javascript:void(0);" class="ct-btn icononly  v-btn--is-elevated" @click="cloasMapInfo()"><div class="icon-close">X</div></a>
                    </div>
                    <div class="map-info-cont mt-md-0 mt-5">
                        <h2 class="m-title pa-md-6 pa-2 mb-0">
                            <div class="txt">
                                {{station_device}}感測器
                            </div>
                        </h2>
                        <v-row class="rt-list pt ma-0 mb-5">
                            <v-col cols="12" md="6" class="item-wrapper">
                                <div class="item pmtwo_item">
                                    <div class="mlt">
                                        <div class="dot"></div>
                                        <div class="title">PM<sub>2.5</sub></div>
                                    </div>
                                    <div class="mlt">
                                        <div class="cont"></div>
                                    </div>
                                </div>
                            </v-col>
                            <v-col cols="12" md="6" class="item-wrapper">
                                <div class="item pmten_item">
                                    <div class="mlt">
                                        <div class="dot"></div>
                                        <div class="title">PM10</div>
                                    </div>
                                    <div class="mlt">
                                        <div class="cont"></div>
                                    </div>
                                </div>
                            </v-col>
                            <v-col cols="12" md="6" class="item-wrapper">
                                <div class="item co_item">
                                    <div class="mlt">
                                        <div class="dot"></div>
                                        <div class="title">CO</div>
                                    </div>
                                    <div class="mlt">
                                        <div class="cont"></div>
                                    </div>
                                </div>
                            </v-col>
                            <v-col cols="12" md="6" class="item-wrapper">
                                <div class="item co2_item">
                                    <div class="mlt">
                                        <div class="dot"></div>
                                        <div class="title">CO2</div>
                                    </div>
                                    <div class="mlt">
                                        <div class="cont"></div>
                                    </div>
                                </div>
                            </v-col>

                            <v-col cols="12" md="6" class="item-wrapper">
                                <div class="item tvoc_item">
                                    <div class="mlt">
                                        <div class="dot"></div>
                                        <div class="title">TVOC</div>
                                    </div>
                                    <div class="mlt">
                                        <div class="cont"></div>
                                    </div>
                                </div>
                            </v-col>
                            <v-col cols="12" md="6" class="item-wrapper">
                                <div class="item no2_item">
                                    <div class="mlt">
                                        <div class="dot"></div>
                                        <div class="title">NO2</div>
                                    </div>
                                    <div class="mlt">
                                        <div class="cont"></div>
                                    </div>
                                </div>
                            </v-col>
                            <v-col cols="12" md="6" class="item-wrapper">
                                <div class="item o3_item">
                                    <div class="mlt">
                                        <div class="dot"></div>
                                        <div class="title">O3</div>
                                    </div>
                                    <div class="mlt">
                                        <div class="cont"></div>
                                    </div>
                                </div>
                            </v-col>
                            <v-col cols="12" md="6" class="item-wrapper">
                                <div class="item temp_item">
                                    <div class="mlt">
                                        <div class="dot"></div>
                                        <div class="title">溫度</div>
                                    </div>
                                    <div class="mlt">
                                        <div class="cont"></div>
                                    </div>
                                </div>
                            </v-col>
                            <v-col cols="12" md="6" class="item-wrapper">
                                <div class="item humi_item">
                                    <div class="mlt">
                                        <div class="dot"></div>
                                        <div class="title">濕度</div>
                                    </div>
                                    <div class="mlt">
                                        <div class="cont"></div>
                                    </div>
                                </div>
                            </v-col>
                            <v-col cols="12" md="6" class="item-wrapper">
                                <div class="item nh3_item">
                                    <div class="mlt">
                                        <div class="dot"></div>
                                        <div class="title">NH3</div>
                                    </div>
                                    <div class="mlt">
                                        <div class="cont"></div>
                                    </div>
                                </div>
                            </v-col>
                            <v-col cols="12" md="6" class="item-wrapper">
                                <div class="item h2s_item">
                                    <div class="mlt">
                                        <div class="dot"></div>
                                        <div class="title">H2S</div>
                                    </div>
                                    <div class="mlt">
                                        <div class="cont"></div>
                                    </div>
                                </div>
                            </v-col>
                        </v-row>
                        <v-card-text>
                            <v-divider></v-divider>
                        </v-card-text>
                        <v-card-actions class="d-flex  justify-sm-center flex-sm-nowrap flex-wrap twocol-box align-start">
                            <div class="me-sm-auto pe-sm-4 mb-sm-0 mb-5">
                                <p class="d-flex align-start mb-6">
                                    <v-icon color="primary">
                                    {{ icons.mdiMapLegend }}
                                    </v-icon>
                                    <span class="ms-3">地區: <span class="address-area"></span></span>
                                </p>
                                <p class="d-flex align-start mb-0">
                                    <v-icon color="primary">
                                    {{ icons.mdiShape }}
                                    </v-icon>
                                    <span class="ms-3">類型: <span class="address-type"></span></span>
                                </p>
                            </div>


                            <div class="ms-sm-auto ps-sm-4">
                                <p class="d-flex align-start mb-6">
                                    <v-icon color="primary">
                                    {{ icons.mdiCity }}
                                    </v-icon>
                                    <span class="ms-3">鎮/區: <span class="address-town"></span></span>
                                </p>
                                <p class="d-flex align-start mb-0">
                                    <v-icon color="primary">
                                    {{ icons.mdiClockTimeTwoOutline }}
                                    </v-icon>
                                    <span class="ms-3">更新時間: <span class="update-time"></span></span>
                                </p>
                            </div>
                        </v-card-actions>
                    </div>
                </div>
            </div>
        </div>

        <!-- Dialog -->
        <v-dialog
          v-model="dialog_position"
          max-width="500px"
        >
            <v-card class="small-input-height">

                <v-card-title class="title-bordb">
                定位
                </v-card-title>
            
                <div class="tabs-area">
                    <v-tabs
                        v-model="tab_position"
                        align-with-title
                    >
                        <v-tab key="ti_1" href='#tab-1' @click="getTabId('tab-1')">感測器</v-tab>
                        <v-tab key="ti_2" href='#tab-2' @click="getTabId('tab-2')">GPS</v-tab>
                    </v-tabs>

                    <div class="pa-5">
                        <v-tabs-items v-model="tab_position">
                            <v-tab-item key="ti_1" value='tab-1'>
                                <v-card flat class="pt-2">
                                    <v-text-field
                                        label="感測器編號"
                                        outlined
                                        dense
                                        placeholder="感測器編號"
                                        hide-details
                                        v-model="pos_station"
                                    ></v-text-field>
                                </v-card>
                            </v-tab-item>
                            <v-tab-item key="ti_2" value='tab-2'>
                                <v-card flat class="pt-2">
                                    
                                    <v-row class="align-center">
                                        <v-col cols="">
                                            <v-text-field
                                                label="地址"
                                                outlined
                                                dense
                                                placeholder="地址"
                                                hide-details
                                                v-model="pos_address"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="auto">
                                            或
                                        </v-col>
                                    </v-row>
                                    
                                    <v-row>
                                        <v-col cols="6" md="6">
                                            <v-text-field
                                                label="經度"
                                                outlined
                                                dense
                                                placeholder="經度"
                                                hide-details
                                                v-model="pos_lon"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="6" md="6">
                                            <v-text-field
                                                label="緯度"
                                                outlined
                                                dense
                                                placeholder="緯度"
                                                hide-details
                                                v-model="pos_lat"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>

                                </v-card>
                            </v-tab-item>
                        </v-tabs-items>
                    </div>    
                </div>
                <div class="close-btn-area">
                    <v-btn
                    class="ct-btn icononly"
                    text
                    @click="dialog_position = false"
                    >
                        <div class="icon-close">X</div>              
                    </v-btn>
                </div>

                <v-divider></v-divider>
                <v-card-actions class="justify-end">
                    <v-btn type="reset" class="mx-2" outlined @click="initDia_pos('pos')">
                    重設
                    </v-btn>
                    <v-btn color="primary" @click="searchPos()">
                    送出
                    </v-btn>
                    
                </v-card-actions>
            </v-card>

        </v-dialog><!-- dialog_position -->

        <v-dialog
          v-model="dialog_filter"
          max-width="500px"
        >
            <v-card>

                <v-card-title class="title-bordb">
                篩選工具
                </v-card-title>


                <v-row class="ma-0">
                    <v-col
                        cols="12"
                        class="pb-0"
                    >
                        
                    <v-select
                        v-model="selectedFilter"
                        :items="filter_items"
                        label="群組"
                        multiple
                        outlined
                        hide-details
                        item-text="value"
                        item-value="value"
                    >
                        <template v-slot:prepend-item>
                        <v-list-item
                            ripple
                            @mousedown.prevent
                            @click="toggle"
                        >
                            <v-list-item-action>
                                <v-icon :color="selectedFilter.length > 0 ? 'indigo darken-4' : ''">
                                    {{ filterIcon }}
                                </v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title>
                                    全選
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                            <v-divider class="mt-2"></v-divider>
                        </template>
                        <template v-slot:append-item>
                        <v-divider class="mb-2"></v-divider>
                        <v-list-item disabled>
                            <v-list-item-avatar color="grey lighten-3">
                            <v-icon>
                                mdi-food-apple
                            </v-icon>
                            </v-list-item-avatar>
                
                        </v-list-item>
                        </template>
                    </v-select>

                    </v-col>

                    <v-col
                        cols="12"
                    >
                        <v-text-field
                        label="門檻"
                        outlined
                        dense
                        placeholder="門檻"
                        hide-details
                        v-model="station_theshold"
                        ></v-text-field>
                    </v-col>
                </v-row>


                <div class="close-btn-area">
                    <v-btn
                    class="ct-btn icononly"
                    text
                    @click="dialog_filter = false"
                    >
                        <div class="icon-close">X</div>              
                    </v-btn>
                </div>

                <v-divider></v-divider>
                <v-card-actions class="justify-end">
                    <v-btn type="reset" class="mx-2" outlined @click="initDia_pos('filt')">
                    重設
                    </v-btn>
                    <v-btn color="primary" @click="searchStation()">
                    送出
                    </v-btn>
                    
                </v-card-actions>
            
            </v-card>
        </v-dialog>


    </div>
</template>

<script>
import { mdiMessageDraw,mdiMapMarkerCircle,mdiFilter,mdiMapLegend,mdiShape,mdiCity,mdiClockTimeTwoOutline,mdiClose
,mdiCheckboxBlankOutline,mdiCheckboxMarked } from '@mdi/js';
import locationIcon from '@/assets/images/all/icon_location.svg';
import locationIcon_red from '@/assets/images/all/icon_location_red.svg';
import locationIcon_yellow from '@/assets/images/all/icon_location_yellow.svg';
import locationIcon_orange from '@/assets/images/all/icon_location_orange.svg';
import locationIcon_purple from '@/assets/images/all/icon_location_purple.svg';
import locationIcon_darkred from '@/assets/images/all/icon_location_darkred.svg';
import locationIcon_epa from '@/assets/images/all/icon_location_epa.svg';
import locationIcon_cusepa_orange from '@/assets/images/all/icon_cusepa_orange.svg';
import axios from 'axios';
import config from '@/config.js';

var dataArry=[];

var pmtwo_val="",pmtwo_color="",temp_val="",temp_color="",tvoc_val="",tvoc_color="",pmten_val="",pmten_color="";
var co_val="",co_color="",co2_val="",co2_color="",no2_val="",no2_color="",o3_val="",o3_color="";
var humi_val="",humi_color="",nh3_val="",nh3_color="",h2s_val="",h2s_color="";

var map;
var marker_basic;
var markerlayerGroup;
var markerlayerClusterGroup;
var intersection = null; //克利金(等濃度圖)
var intersectionLayer; //克利金(等濃度圖)
var markers_pos =[];
var LeafIcon;
var greenIcon,redIcon,yellowIcon,orangeIcon,purpleIcon,darkredIcon,epaIcon,cusepaIcon_orange;
var pm25Heat=[],tvocHeat=[];
var default_latlon;
var station_devices_arry=[];
let levelV;
let colors;

export default{
    components: {
        mdiMessageDraw,
        mdiMapMarkerCircle,
        mdiFilter,
        mdiMapLegend,
        mdiShape,
        mdiCity,
        mdiClockTimeTwoOutline,
        mdiClose,
        mdiCheckboxBlankOutline
    },
    name: 'Map',
    data: () => ({
        valTitle_items_defaultSelected:'pm25',
        //valTitle_items: ['PM2.5', 'PM10', 'CO','CO2','TVCO','NO2','O3','溫度','濕度','NH3','H2S'],
        valTitle_items:[{
            text:'PM2.5',
            value:'pm25'
        },{
            text:'PM10',
            value:'pm10'
        },{
            text:'CO',
            value:'co'
        },{
            text:'CO2',
            value:'co2'
        },{
            text:'TVCO',
            value:'tvoc'
        },{
            text:'NO2',
            value:'no2'
        },{
            text:'O3',
            value:'o3'
        },{
            text:'溫度',
            value:'temp'
        },{
            text:'濕度',
            value:'hum'
        },{
            text:'NH3',
            value:'nh3'
        },{
            text:'H2S',
            value:'h2s'
        }],
        cluster_status:false,
        icons: {
            mdiMessageDraw,
            mdiMapMarkerCircle,
            mdiFilter,
            mdiMapLegend,
            mdiShape,
            mdiCity,
            mdiClockTimeTwoOutline,
            mdiCheckboxBlankOutline,
            mdiCheckboxMarked
        },
        dialog_position: false,
        tab_position:'tab-1',
        dialog_filter: false,

        filter_items: [], //群組
        selectedFilter: [],
        pos_station:"",
        pos_address:"",
        pos_lon:"",
        pos_lat:"",
        station_device:"",
        station_theshold:"",
        inline_address_search:"", //地圖上的地址搜尋
        isKir:false //判斷克利金
    }),
    created(){
        let nav_arry_str=[];
        //檢查會員權限
        const apiurl_authMem = config.geturl(config.API.exeAuthMem,{});
        const memlogin_url = config.geturl(config.API.memLogin,{});
        axios.get(apiurl_authMem).then(response => {
            //console.log(response.data);
            if(response.data.isOk){
                let nav_str_ay = response.data.log.split(',');
                nav_str_ay.forEach(function(item){
                    nav_arry_str.push(parseInt(item));
                });
                if(!nav_arry_str.includes(2)){
                    alert("您沒有該頁權限，請重新登入");
                    location.href=memlogin_url;
                }
            }else{
                alert("您沒有該頁權限，請重新登入");
                location.href=memlogin_url;
            }
        });
    },
    mounted(){


        var self=this;
        $(function() {
            $(".nav_mainTitle").text("地圖");
        });

        //感測器編號
        const apiurl_stationInfo=config.geturl(config.API.infostation,{});
        axios.get(apiurl_stationInfo).then(response => {
            if(response.data !== ""){
                var arry = response.data;
                var station_arry=[];
                var group_arry=[];
                $.each(arry,function(index,item){
                    if(item.id !== "" && item.id !== null && item.device !== "" && item.device !== null){
                        station_arry.push({
                            id:item.id,
                            device:item.device,
                            lat:item.lat,
                            lon:item.lon,
                            address:item.town + item.area + item.road,

                        });
                        group_arry.push({
                            value:item.road
                        });
                    }
                });
                const groupfilteredArray = unique(group_arry);
                this.filter_items = groupfilteredArray;
                station_devices_arry = response.data;
            }
        });




        //地圖
        const apiurl=config.geturl(config.API.realtime,{limit:1000});
        axios.get(apiurl).then(response => {
            
            let pu_str = self.valTitle_items_defaultSelected;
            
            var polygon;
            var overLatlng;
            var overRadius=200;
            
            map = L.map('leafletmap').fitWorld();
            map.locate({setView: true, maxZoom: 16});
            //預設定位在台中中區
            default_latlon = [24.1746431,120.6774942];
            map.setView(default_latlon, 13);

            /*map.on('locationfound', function(e){
                var radius = e.accuracy;
                //L.marker(e.latlng).addTo(map)
                //    .bindPopup("系統預設位置").openPopup();
                //L.circle(e.latlng, radius).addTo(map);
                overLatlng = e.latlng;
                overRadius = radius;
                default_latlon = overLatlng;
            });*/
            /*map.on('locationerror', function(){
                //不允許就定位在中區
                var radius = 1952.9697806214715;
                L.marker([24.1746431,120.6774942]).addTo(map)
                    .bindPopup("系統預設位置").openPopup();
                //L.circle([24.1746431,120.6774942], radius).addTo(map);
                overLatlng = [24.1746431,120.6774942];
                overRadius = radius;

                map.setView([24.1746431,120.6774942],13);
            });*/

            //全螢幕
            map.addControl(new L.Control.Fullscreen());
            //ruler
            var options = {
                position: 'topleft',
                lengthUnit: {                 // You can use custom length units. Default unit is kilometers.
                    display: 'km',              // This is the display value will be shown on the screen. Example: 'meters'
                    decimal: 2,                 // Distance result will be fixed to this value. 
                    factor: null,               // This value will be used to convert from kilometers. Example: 1000 (from kilometers to meters)  
                    label: '距離:'           
                },
                angleUnit: {
                    display: '&deg;',           // This is the display value will be shown on the screen. Example: 'Gradian'
                    decimal: 2,                 // Bearing result will be fixed to this value.
                    factor: null,                // This option is required to customize angle unit. Specify solid angle value for angle unit. Example: 400 (for gradian).
                    label: '角度:'
                }
            };
            L.control.ruler(options).addTo(map);


            markerlayerGroup = L.layerGroup().addTo(map);
            markerlayerClusterGroup = L.markerClusterGroup();
            var windcwblayerGroup = L.layerGroup();
            var epaPartlayerGroup = L.layerGroup();
            var windGloballayerGroup = L.layerGroup();
            var windbarblayerGroup = L.layerGroup();
            var windbarbMarklayerGroup = L.layerGroup();
            var epalayerGroup = L.layerGroup();
            var windbarbEpalayerGroup = L.layerGroup();
            var pm25HeatlayerGroup = L.layerGroup();
            var tvocHeatlayerGroup = L.layerGroup();

            var HeatlayerGroup = L.layerGroup();
            //圖層
            var baselayers = {
            '街景': L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'),
            '地景圖': L.tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}')
            };
            //複選圖層
            var overlays = {
                '環保局':epaPartlayerGroup,
                '環保局風標':windbarblayerGroup,
                '氣象站':windcwblayerGroup,
                '微型感測器':markerlayerGroup,
                '微型感測器風標':windbarbMarklayerGroup,
                '風場':windGloballayerGroup,
                'PM2.5汙染熱區':pm25HeatlayerGroup,
                'TVOC汙染熱區':tvocHeatlayerGroup,
                '環保署':epalayerGroup,
                '環保署風標':windbarbEpalayerGroup,
            };
            L.control.layers(baselayers, overlays).addTo(map);
            baselayers['街景'].addTo(map);


            LeafIcon = L.Icon.extend({
                options: {
                    iconSize: [15, 15],
                iconAnchor: [0, 0],
                popupAnchor: [0, 0]
                }
            });

            greenIcon = new LeafIcon({iconUrl: locationIcon});
                redIcon = new LeafIcon({iconUrl: locationIcon_red});
                yellowIcon = new LeafIcon({iconUrl: locationIcon_yellow});
                orangeIcon = new LeafIcon({iconUrl: locationIcon_orange});
                purpleIcon = new LeafIcon({iconUrl: locationIcon_purple});
                darkredIcon = new LeafIcon({iconUrl: locationIcon_darkred});
                epaIcon = new LeafIcon({iconUrl: locationIcon_epa});
                cusepaIcon_orange = new LeafIcon({iconUrl: locationIcon_cusepa_orange});

            L.icon = function (options) {
                return new L.Icon(options);
            };

            //全部出現
        
            dataArry=response.data;

            let heatData=[],heat_x=[],heat_y=[];

            //一開始就出現所有點
            var i = 0,currTime="";
            $.each(dataArry, function() {
                var iconType=greenIcon;
                    if(dataArry[i].pm25 !== "" && dataArry[i].pm25 !== null && typeof(dataArry[i].pm25) !== "undefined"){
                        if(parseFloat(dataArry[i].pm25) <= 15.4){
                            iconType=greenIcon;
                        }else if(parseFloat(dataArry[i].pm25) > 15.4 && parseFloat(dataArry[i].pm25) <= 35.4){
                            iconType=yellowIcon;
                        }else if(parseFloat(dataArry[i].pm25) > 35.4 && parseFloat(dataArry[i].pm25) <= 54.4){
                            iconType=orangeIcon;
                        }else if(parseFloat(dataArry[i].pm25) > 54.4 && parseFloat(dataArry[i].pm25) <= 150.4){
                            iconType=redIcon;
                        }else if(parseFloat(dataArry[i].pm25) > 150.4 && parseFloat(dataArry[i].pm25) <= 250.4){
                            iconType=purpleIcon;
                        }else{
                            iconType=darkredIcon;
                        }
                    }

                    if(dataArry[i].lat !== "" && dataArry[i].lat !== null && typeof(dataArry[i].lat) !== "undefined" && dataArry[i].lon !== "" && dataArry[i].lon !== null && typeof(dataArry[i].lon) !== "undefined"){
                        marker_basic = L.marker([dataArry[i].lat,dataArry[i].lon], {icon: iconType,title:dataArry[i].id}).addTo(markerlayerGroup);
                        //marker_basic.id=dataArry[i].id;
                        //$(marker_basic).attr("id",dataArry[i].id);
                        //微型感測器風標
                        if(dataArry[i].wd !== "" && dataArry[i].wd !== null && typeof(dataArry[i].wd) !== "undefined" && dataArry[i].ws !== "" && dataArry[i].ws !== null && typeof(dataArry[i].ws) !== "undefined"){
                            var Micon = L.WindBarb.icon({deg: dataArry[i].wd, speed: dataArry[i].ws,pointRadius: 4, strokeLength: 10,fillColor:"#8BED00",strokeWidth:1});
                            var windbab_marker = L.marker([parseFloat(dataArry[i].lat),parseFloat(dataArry[i].lon)], {icon: Micon});
                            windbab_marker.bindPopup("<div class='lc-title'>感測器：" + dataArry[i].device + "</div><div class='lc-cont'><div class='row'><div class='col-md-6 col-12 item'>風向：" + dataArry[i].wd + "</div><div class='col-md-6 col-12 item'>" + "風速：" + dataArry[i].ws + "</div><div class='col-12 item pt-0'>" + "<div class='hint'>更新時間：" + dataArry[i].time + "</div></div></div>")
                            windbarbMarklayerGroup.addLayer(windbab_marker);
                        }
                        //PM2.5汙染熱區
                        if(dataArry[i].pm25 !== "" && dataArry[i].pm25 !== null && typeof(dataArry[i].pm25) !== "undefined"){
                            pm25Heat.push([parseFloat(dataArry[i].lat),parseFloat(dataArry[i].lon),dataArry[i].pm25]);
                        }
                        //TVOC汙染熱區
                        if(dataArry[i].tvoc !== "" && dataArry[i].tvoc !== null && typeof(dataArry[i].tvoc) !== "undefined"){
                            tvocHeat.push([parseFloat(dataArry[i].lat),parseFloat(dataArry[i].lon),dataArry[i].tvoc]);
                        }
                    }

                    var mp_co=(dataArry[i].co !== null)?dataArry[i].co:"0";
                    var mp_co2=(dataArry[i].co2 !== null)?dataArry[i].co2:"0";
                    var mp_no2 =(dataArry[i].no2 !== null)?dataArry[i].no2:"0";
                    var mp_o3 =(dataArry[i].o3 !== null)?dataArry[i].o3:"0";
                    var mp_hum=(dataArry[i].hum !== null)?dataArry[i].hum:"0";
                    var mp_nh3 =(dataArry[i].nh3 !== null)?dataArry[i].nh3:"0";
                    var mp_h2s =(dataArry[i].h2s !== null)?dataArry[i].h2s:"0";

                    //marker.bindPopup("<div class='lc-title'>感測器：" + dataArry[i].device + "</div><div class='lc-cont'><div class='row'><div class='col-md-6 col-12 item'>PM<sub>2.5</sub>：" + dataArry[i].pm25 + "<br/>溫度：" + dataArry[i].temp + "<br/>TVOC：" + dataArry[i].tvoc + "<br/>PM10：" + dataArry[i].pm10 + "<br>CO：" + mp_co + "<br>CO2：" + mp_co2 + "<br>NO2：" + mp_no2 + "</div><div class='col-md-6 col-12 item'>" + "O3：" + mp_o3 + "<br>濕度：" + mp_hum + "<br>NH3：" + mp_nh3 + "<br>H2S：" + mp_h2s +"<br>地區：" + dataArry[i].area+"<br>類型：" + dataArry[i].areatype+"<br>鎮/區：" + dataArry[i].town + "</div><div class='col-12 item'><div class='hint'>更新時間：" + dataArry[i].time + "</div></div></div>")

                    marker_basic.on('click', function (e) {
                        /*$(".leaflet-marker-icon").removeClass("selectedMarker");
                        $(e.target._icon).addClass('selectedMarker');*/
                        var currLat = e.latlng.lat,currLng = e.latlng.lng;
                        var currData=dataArry.filter(item => parseFloat(item.lat) === currLat && parseFloat(item.lon) === currLng);
                            if(currData.length > 0){

                                self.station_device = currData[0].device;

                                markerLayerData(currData[0]);


                            }else{
                                //沒資料
                                
                            }
                            if(!$(".map-area .map-info").hasClass("open")){
                                $(".map-area .map-info").addClass("open");
                            }

                    }).addTo(markerlayerGroup);


                    markers_pos.push(marker_basic);
                    markerlayerGroup.addLayer(marker_basic);
                    markerlayerClusterGroup.addLayer(marker_basic);

                    //heat 等濃度
                    let pu_value = "";
                    if(pu_str == "pm25"){pu_value = dataArry[i].pm25;}
                    else if(pu_str == "pm10"){pu_value = dataArry[i].pm10;}
                    else if(pu_str == "co"){pu_value = dataArry[i].co;}
                    else if(pu_str == "co2"){pu_value = dataArry[i].co2;}
                    else if(pu_str == "tvoc"){pu_value = dataArry[i].tvoc;}
                    else if(pu_str == "no2"){pu_value = dataArry[i].no2;}
                    else if(pu_str == "o3"){pu_value = dataArry[i].o3;}
                    else if(pu_str == "temp"){pu_value = dataArry[i].temp;}
                    else if(pu_str == "hum"){pu_value = dataArry[i].hum;}
                    else if(pu_str == "nh3"){pu_value = dataArry[i].nh3;}
                    else if(pu_str == "h2s"){pu_value = dataArry[i].h2s;}
                    //heat_x.push()
                    heatData.push([parseFloat(dataArry[i].lat),parseFloat(dataArry[i].lon),pu_value]);


                i++;

            });

            //heat 等濃度
            /*var heatLayer = L.heatLayer(heatData, {radius: 25,blur:0});
            HeatlayerGroup.addLayer(heatLayer).addTo(map);*/

            //氣象站
            const apiurl_cwbwind=config.geturl(config.API.cwb_wind,{});
            axios.get(apiurl_cwbwind).then(response => {
                if(response.data !== "" && response.data.length > 0){

                    var cwbWindData = response.data;
                    $.each(cwbWindData,function(index,cw_item){
                        //風向
                        var wd_d = cw_item.wd;
                        var lat_pos=cw_item.lat;
                        var lon_pos=cw_item.lon;
                        var wdCul = wd_cul(wd_d);
                        var lat_cul=Math.cos(wd_d),lon_cul=Math.sin(wd_d);
                        if(wdCul=="1"){
                            lat_cul = Math.abs(lat_cul);
                            lon_cul = Math.abs(lon_cul);
                        }else if(wdCul=="2"){
                            lat_cul = Math.abs(lat_cul) * -1;
                            lon_cul = Math.abs(lon_cul);
                        }else if(wdCul=="3"){
                            lat_cul=Math.abs(lat_cul) * -1;
                            lon_cul=Math.abs(lon_cul) * -1;
                        }else if(wdCul=="4"){
                            lat_cul=Math.abs(lat_cul);
                            lon_cul=Math.abs(lon_cul) * -1;
                        }
                        
                        var polyline = L.polyline([[lat_pos+(lat_cul/1000),lon_pos+(lon_cul/1000)],[lat_pos,lon_pos]], {});
                        var decorator = L.polylineDecorator(polyline, {
                                patterns: [
                                    {offset: '100%', repeat: 0, symbol: L.Symbol.arrowHead({pixelSize: 15, polygon: false, pathOptions: {stroke: true}})}
                                ]
                            });


                        decorator.bindPopup("<div class='lc-title'>" + cw_item.name + "測站</div><div class='lc-cont'><div class='row'><div class='col-md-6 col-12 item'>溫度：" + cw_item.temp + "<br/>濕度：" + cw_item.hum + "</div><div class='col-md-6 col-12 item'>" + "風向：" + cw_item.wd + "<br>風速：" + cw_item.ws + "</div><div class='col-12 item pt-0'>大氣壓力：" + cw_item.press + "<div class='hint'>更新時間：" + cw_item.time + "</div></div></div>")
                        polyline.bindPopup("<div class='lc-title'>" + cw_item.name + "測站</div><div class='lc-cont'><div class='row'><div class='col-md-6 col-12 item'>溫度：" + cw_item.temp + "<br/>濕度：" + cw_item.hum + "</div><div class='col-md-6 col-12 item'>" + "風向：" + cw_item.wd + "<br>風速：" + cw_item.ws + "</div><div class='col-12 item pt-0'>大氣壓力：" + cw_item.press + "<div class='hint'>更新時間：" + cw_item.time + "</div></div></div>")
                            windcwblayerGroup.addLayer(polyline);
                        windcwblayerGroup.addLayer(decorator);

                    });

                }
            })


            

            //風場
            const apiurl_envwind=config.geturl(config.API.env_wind,{});
            axios.get(apiurl_envwind).then(response => {
                if(response.data !== "" && response.data.length > 0){
                    var velocityLayer = L.velocityLayer({
                        displayValues: false,
                        displayOptions: {
                        velocityType: "Global Wind",
                        position: "bottomleft",
                        emptyString: "No wind data"
                        },
                        data: response.data,
                        velocityScale:0.015,
                        maxVelocity: 10,
                        colorScale: ['#bd0026', '#f03b20', '#fd8d3c', '#fecc5c', '#ffffb2']
                    });
                    windGloballayerGroup.addLayer(velocityLayer);
                }
            });
            
            var latlng = L.latLng(50.5, 30.5);
            var geo_marker = L.marker(latlng,{
                draggable: true,
                autoPan: true
            });

            //環保署
            const apiurl_epa=config.geturl(config.API.env_epa,{limit:1000});
            axios.get(apiurl_epa).then(response => {
                if(response.data !== "" && response.data.length > 0){
                    var epaData = response.data;
                    var icon_epa=epaIcon;
                    $.each(epaData,function(index,epitem){
                        var epa_marker;
                        var lat_epa=epitem.lat,lon_epa=epitem.lon;
                        if(lat_epa !== "" && lat_epa !== null && typeof(lat_epa) !== "undefined" && lon_epa !== "" && lon_epa !== null && typeof(lon_epa) !== "undefined"){
                            epa_marker = L.marker([lat_epa,lon_epa], {icon: icon_epa});
                            epa_marker.bindPopup("<div class='lc-title'>環保署" + epitem.name + "測站</div><div class='lc-cont'><div class='row'><div class='col-md-6 col-12 item'>AQI數值：" + epitem.aqi + "<br/>CO：" + epitem.co+ "<br/>CO平均數值：" + epitem.co_8hr + "<br>NO：" + epitem.no + "<br>NO2：" + epitem.no2 + "<br>NOx：" + epitem.nox + "<br>O3：" + epitem.o3 + "<br>O3平均數值：" + epitem.o3_8hr + "</div><div class='col-md-6 col-12 item'>" + "PM2.5：" + epitem.pm25 + "<br>PM2.5平均數值：" + epitem.pm25_avg + "<br>PM10：" + epitem.pm10 + "<br>PM10平均數值：" + epitem.pm10_avg + "<br>SO2：" + epitem.so2 + "<br>SO2：平均數值：" + epitem.so2_avg + "<br>風向：" + epitem.wd + "<br>風速：" + epitem.ws  + "</div><div class='col-12 item pt-0'>PM2.5：" + epitem.pm25 + "<div class='hint'>更新時間：" + epitem.time + "</div></div></div>")
                            if(epitem.wd !== "" && epitem.wd !== null && typeof(epitem.wd) !== "undefined" && epitem.ws !== "" && epitem.ws !== null && typeof(epitem.ws) !== "undefined"){
                                var epaWIcon = L.WindBarb.icon({deg: epitem.wd, speed: epitem.ws,pointRadius: 4, strokeLength: 10,fillColor:"#FFA300",strokeWidth:1});
                                var windbab_marker = L.marker([parseFloat(lat_epa),parseFloat(lon_epa)], {icon: epaWIcon});
                                windbab_marker.bindPopup("<div class='lc-title'>" + epitem.name + "</div><div class='lc-cont'><div class='row'><div class='col-md-6 col-12 item'>風向：" + epitem.wd + "</div><div class='col-md-6 col-12 item'>" + "風速：" + epitem.ws + "</div><div class='col-12 item pt-0'>" + "<div class='hint'>更新時間：" + epitem.time + "</div></div></div>")
                                windbarbEpalayerGroup.addLayer(windbab_marker);
                            }
                        }
                        epalayerGroup.addLayer(epa_marker);
                    });
                }
            });

            //環保局
            const apiurl_cusreal=config.geturl(config.API.cus_realtime,{});
            axios.get(apiurl_cusreal).then(response => {
                if(response.data !== "" && response.data.length > 0){
                    var alldr = response.data;
                    var windbarb_arry,epa_arry;
                    for(var a=0;a<alldr.length;a++){
                        if(alldr[a].no == 2){
                            epa_arry = alldr[a].stations;
                        }
                        if(alldr[a].no == 3){
                            windbarb_arry = alldr[a].stations;
                        }
                    }
                    //環保局
                    var icon_epa=cusepaIcon_orange;
                    $.each(epa_arry,function(index,epitem){
                        var epa_marker;
                        var lat_epa=epitem.lat,lon_epa=epitem.lon;
                        if(lat_epa !== "" && lat_epa !== null && typeof(lat_epa) !== "undefined" && lon_epa !== "" && lon_epa !== null && typeof(lon_epa) !== "undefined"){
                            epa_marker = L.marker([lat_epa,lon_epa], {icon: icon_epa});
                            epa_marker.bindPopup("<div class='lc-title'>環保局" + epitem.name + "</div><div class='lc-cont'><div class='row'><div class='col-md-6 col-12 item'>PM2.5：" + epitem.pm25 + "<br/>PM10：" + epitem.pm10 + "<br/>CO：" + epitem.co+ "<br>NO：" + epitem.no + "</div><div class='col-md-6 col-12 item'>" + "NO2：" + epitem.no2 + "<br>NOx：" + epitem.nox + "<br>O3：" + epitem.o3 + "<br>SO2：" + epitem.so2 + "</div><div class='col-12 item pt-0'>" + "<div class='hint'>更新時間：" + epitem.time + "</div></div></div>")
                        }
                        epaPartlayerGroup.addLayer(epa_marker);
                    });

                    //環保局風標
                    if(windbarb_arry.length > 0){
                        $.each(windbarb_arry,function(index,wb_item){
                            var icon = L.WindBarb.icon({deg: wb_item.wd, speed: wb_item.ws,pointRadius: 4, strokeLength: 10,fillColor:"#DE6057",strokeWidth:1});
                            var windbab_marker = L.marker([parseFloat(wb_item.lat),parseFloat(wb_item.lon)], {icon: icon});
                            windbab_marker.bindPopup("<div class='lc-title'>" + wb_item.name + "</div><div class='lc-cont'><div class='row'><div class='col-md-6 col-12 item'>風向：" + wb_item.wd + "</div><div class='col-md-6 col-12 item'>" + "風速：" + wb_item.ws + "</div><div class='col-12 item pt-0'>" + "<div class='hint'>更新時間：" + wb_item.time + "</div></div></div>")
                            windbarblayerGroup.addLayer(windbab_marker);
                        });
                    }
                }
            });

            //PM2.5汙染熱區
            var heatPM25Map = L.heatLayer(pm25Heat, {
                radius: 25,
                blur:30
            });
            pm25HeatlayerGroup.addLayer(heatPM25Map);

            //TVOC汙染熱區
            var heatTVOCMap = L.heatLayer(tvocHeat, {
                radius: 25,
                blur:30
            });
            tvocHeatlayerGroup.addLayer(heatTVOCMap);


            //克利金
            intersectionLayer = L.layerGroup();
            
                

            //地址搜尋

/*

const provider = new GeoSearch.OpenStreetMapProvider({
                        params: {
                            'accept-language': 'tw',
                            countrycodes: 'tw',
                            addressdetails: 1,
                            limit: 100,
                            //viewbox: [25.140,-80.866,26.337,-80.061]
                        }
                    });
                    provider.search({ query: f_address }).then(function (result) {
                    // do something with result;
                        map.setView([result[0].y, result[0].x], 13);
                    });

*/

            /*const searchControl = new GeoSearch.GeoSearchControl({
                notFoundMessage: '查無此地址',
                provider: new GeoSearch.OpenStreetMapProvider({
                    params: {
                        'accept-language': 'tw',
                        countrycodes: 'tw',
                        addressdetails: 1,
                        limit: 100,
                        //viewbox: [25.140,-80.866,26.337,-80.061]
                    }
                }),
                showMarker: false, // optional: true|false  - default true
                showPopup: false, // optional: true|false  - default false
                //marker: geo_marker,
                searchLabel:"請輸入地址",
                //popupFormat: ({ query, result }) => result.x.toString(), // optional: function    - default returns result label,
                //resultFormat: ({ result }) => result.label, // optional: function    - default returns result label
                //keepResult: false, // optional: true|false  - default false
                style: 'bar'
            });*/

            //map.addControl(searchControl);

            //map.on('geosearch/showlocation', () => {
                
                //e.Locations.forEach(function (Location) {
                //    console.log(Location.location);
                    // Location.Label = full address
                    // Location.X = longitude
                    // Location.Y = latitude
                    // Location.bounds = boundaries
                //});
                //if (geo_marker) {
                    //map.removeControl(geo_marker);
                //}
            //});   




            //群集模式
    
            $(".cluster-ckb").find("input").change(function(){
                if($(this).is(":checked")){
                    //重畫群集
                    //markerlayerGroup.clearLayers();
                    map.removeLayer(markerlayerGroup);
                    markerlayerClusterGroup.addTo(map);
                    //markerlayerGroup = L.markerClusterGroup().addTo(map);

                    //markerAdd(markerlayerGroup);
                }else{
                    map.removeLayer(markerlayerClusterGroup);
                    markerlayerGroup.addTo(map);
                    //map.removeLayer(markerlayerGroup);
                    //markerlayerGroup = L.layerGroup().addTo(map);
                    //markerAdd(markerlayerGroup);
                }
            });



        })

    },
    computed: {
        AllFilterSelected () {
        return this.selectedFilter.length === this.filter_items.length
        },
        SomeFilterSelected () {
        return this.selectedFilter.length > 0 && !this.AllFilterSelected
        },
        filterIcon () {
            if (this.AllFilterSelected) return mdiCheckboxMarked
            if (this.SomeFilterSelected) return mdiCheckboxBlankOutline
            return mdiCheckboxBlankOutline
        },
    },

    methods: {
        getTabId(tab_id){
            this.tab_position = tab_id;
            //console.log(this.tab_position);
        },
        toggle () {
            this.$nextTick(() => {
                if (this.AllFilterSelected) {
                this.selectedFilter = []
                } else {
                this.selectedFilter = this.filter_items.slice()
                }
            })
        },
        cloasMapInfo(){
            $(".map-area .map-info").removeClass("open");
        },
        checkCkuster(){
            /*if(this.cluster_status){
                if(markerlayerGroup !== null && typeof(markerlayerGroup) !== "undefined"){
                    //重畫群集
                    map.removeLayer(markerlayerGroup);
                    markerlayerGroup = L.markerClusterGroup().addTo(map);
                    markerAdd(markerlayerGroup);
                }
            }else{
                if(markerlayerGroup !== null && typeof(markerlayerGroup) !== "undefined"){
                    map.removeLayer(markerlayerGroup);
                    markerlayerGroup = L.layerGroup().addTo(map);
                    markerAdd(markerlayerGroup);
                }
            }*/
            
        },
        openDia_pos(cat){
            if(cat == "pos"){
                this.dialog_position = true;
            }else if(cat == "filt"){
                this.dialog_filter = true;
            }
            //初始化對話框數值
            this.initDia_pos(cat);
        },
        initDia_pos(cat){
            if(cat=="pos"){
                this.pos_station="";
                this.pos_address="";
                this.pos_lon="";
                this.pos_lat="";
            }else if(cat == "filt"){
                this.station_theshold="";
                this.selectedFilter = [];
                $(".map-area .map-info").removeClass("open");
            }
            
        },
        searchPos(){

            //感測器編號
            if(this.tab_position == "tab-1"){
                var currData = dataArry.filter(item => item.id === this.pos_station);
                if(currData.length > 0){
                    var first_data = currData[0];

                    $(".leaflet-marker-icon").removeClass("selectedMarker");
                    $(".leaflet-marker-icon[title='" + first_data.id + "']").addClass('selectedMarker');
                    this.station_device = first_data.device;
                    if(first_data.lat != "" && first_data.lat != null && typeof(first_data.lat) != "undefined" && first_data.lon != "" && first_data.lon != null && typeof(first_data.lon) != "undefined"){
                        map.setView([parseFloat(first_data.lat), parseFloat(first_data.lon)], 13);
                        markerLayerData(first_data,true);
                    }
                    console.log(first_data.lat + "///" + first_data.lon);
                    //map = L.map('map').setView([parseFloat(first_data.lat), parseFloat(first_data.lon)], 100);
                    
                }
            }else if(this.tab_position == "tab-2"){ //地址、經緯度
                var default_lat = default_latlon[0];
                var default_lon = default_latlon[1];
                var f_lat = this.pos_lat;
                var f_lon = this.pos_lon;
                var f_address = this.pos_address;
                
                if(f_lat !=="" || f_lon !== ""){
                    var f_lat_float = (f_lat !=="")?parseFloat(f_lat):parseFloat(default_lat);
                    var f_lon_float = (f_lon !=="")?parseFloat(f_lon):parseFloat(default_lon);
                    map.setView([f_lat_float, f_lon_float], 13);
                }
                if(f_address !==""){
                    // setup
                    this.searchByAddress(f_address);

                    /*const provider = new GeoSearch.OpenStreetMapProvider({
                        params: {
                            'accept-language': 'tw',
                            countrycodes: 'tw',
                            addressdetails: 1,
                            limit: 100,
                            //viewbox: [25.140,-80.866,26.337,-80.061]
                        }
                    });
                    provider.search({ query: f_address }).then(function (result) {
                    // do something with result;
                        map.setView([result[0].y, result[0].x], 13);
                    });*/
                }

            }

            //關閉對話框
            this.dialog_position = false;
            
        },
        searchStation(){
            var self=this;
            var hasPu=true;
            var new_arry=[];
            var pollute_cat=this.valTitle_items_defaultSelected; //汙染值(pm25...)
            var road_group= this.selectedFilter;
            //門檻值
            if(this.station_theshold != "" && this.station_theshold != null){
                for (var i = 0; i < dataArry.length; i++) {
                    for (var idx_Key in dataArry[i]) {
                        //以本例而言，自然分別會是：id、device、env....
                        //alert(JData[i][idx_Key]);
                        if(idx_Key == pollute_cat){
                            var pu_val = dataArry[i][idx_Key];
                            if(parseFloat(pu_val) >= parseFloat(this.station_theshold)){
                                //hasPu=true;
                                new_arry.push(dataArry[i]);
                            }
                        }
                        
                    }
                }
            }else{
                new_arry = dataArry;
            }
            
            //群組
            if(road_group.length > 0){
                var exitIds=[];
                //let group_selected_arry = this.selectedFilter;
                $.each(station_devices_arry,function(index,item){
                    var address=item.town + item.area + item.road;
                    for(var rg=0;rg < road_group.length;rg++){
                        if(address.indexOf(road_group[rg]) > -1){
                            exitIds.push(item.device);
                        }
                    }
                });

                var ff_arry = [];
                for (var ffi = 0; ffi< new_arry.length; ffi++){
                    for (var ffj = 0; ffj < exitIds.length; ffj++){
                        if(new_arry[ffi].id == exitIds[ffj]){
                            ff_arry.push(new_arry[ffi]);
                        }
                    }
                }
                new_arry = ff_arry;  
            }

            if(this.selectedFilter.length == 0 && (this.station_theshold == "" || this.station_theshold == null)){
                new_arry = dataArry;
            }

            if(hasPu){
                markerlayerGroup.clearLayers();
                markerlayerClusterGroup.clearLayers();
                markers_pos =[];

                $.each(new_arry,function(index,item){
                    var iconType=greenIcon;
                    if(item.pm25 !== "" && item.pm25 !== null && typeof(item.pm25) !== "undefined"){
                        if(parseFloat(item.pm25) <= 15.4){
                            iconType=greenIcon;
                        }else if(parseFloat(item.pm25) > 15.4 && parseFloat(item.pm25) <= 35.4){
                            iconType=yellowIcon;
                        }else if(parseFloat(item.pm25) > 35.4 && parseFloat(item.pm25) <= 54.4){
                            iconType=orangeIcon;
                        }else if(parseFloat(item.pm25) > 54.4 && parseFloat(item.pm25) <= 150.4){
                            iconType=redIcon;
                        }else if(parseFloat(item.pm25) > 150.4 && parseFloat(item.pm25) <= 250.4){
                            iconType=purpleIcon;
                        }else{
                            iconType=darkredIcon;
                        }
                    }
                    if(item.lat !== "" && item.lat !== null && typeof(item.lat) !== "undefined" && item.lon !== "" && item.lon !== null && typeof(item.lon) !== "undefined"){
                        marker_basic = L.marker([item.lat,item.lon], {icon: iconType,title:item.id}).addTo(markerlayerGroup).addTo(markerlayerClusterGroup);
                    }

                    marker_basic.on('click', function (e) {
                        $(".leaflet-marker-icon").removeClass("selectedMarker");
                        $(e.target._icon).addClass('selectedMarker');
                        var currLat = e.latlng.lat,currLng = e.latlng.lng;
                        var currData=new_arry.filter(item => parseFloat(item.lat) === currLat && parseFloat(item.lon) === currLng);
                            if(currData.length > 0){

                                self.station_device = currData[0].device;

                                markerLayerData(currData[0]);


                            }else{
                                //沒資料
                                
                            }
                            if(!$(".map-area .map-info").hasClass("open")){
                                $(".map-area .map-info").addClass("open");
                            }

                    }).addTo(markerlayerGroup).addTo(markerlayerClusterGroup);

                });
                markers_pos.push(marker_basic);
                //markerlayerGroup.addLayer(marker_basic);
                //markerlayerClusterGroup.addLayer(marker_basic);
            }


            /*if(currData.length > 0){
                console.log(currData);
            }*/
            //關閉對話框
            this.dialog_filter = false;
        },
        searchByAddress(addrVal = ""){
            let self = this;
            if(addrVal == "") addrVal = this.inline_address_search;
            if(addrVal != ""){
                const provider = new GeoSearch.OpenStreetMapProvider({
                        params: {
                            'accept-language': 'tw',
                            countrycodes: 'tw',
                            addressdetails: 1,
                            limit: 100,
                            //viewbox: [25.140,-80.866,26.337,-80.061]
                        }
                    });
                    
                    provider.search({ query: addrVal }).then(function (result) {
                        if(result.length > 0){
                            // do something with result;
                            map.setView([result[0].y, result[0].x], 13);
                            //移到該位置後，清除搜尋
                            self.inline_address_search="";
                        }else{
                            //搜不到原地址，刪減字數
                            let addr_length = addrVal.length;
                            if(addr_length > 0){
                                addrVal = addrVal.slice(0,addr_length-1);
                                self.searchByAddress(addrVal);
                                //console.log(addrVal);
                            }else{
                                alert("查無資料");
                            }
                            
                        }
                    });
            }
        },
        addHeatLayer(){
            let self  = this;
            //HeatlayerGroup.addTo(map);
            this.isKir = !this.isKir;
            
            if(this.isKir){
                //先清空
                intersection = null;
                map.removeLayer(intersectionLayer);

                let dd1 = turf.bbox(boundaries);
                let points1 = {
                    type: "FeatureCollection",
                    features: []
                }
                let hl_val = self.valTitle_items_defaultSelected;
                dataArry.map(item => {
                    
                    let spe_val=null;
                    switch (hl_val){
                        case "pm25":
                            spe_val = (item.pm25 != null && item.pm25 != "")?item.pm25:null;
                            break;
                        case "pm10":
                            spe_val = (item.pm10 != null && item.pm10 != "")?item.pm10:null;
                            break;
                        case "co":
                            spe_val = (item.co != null && item.co != "")?item.co:null;
                            break;
                        case "co2":
                            spe_val = (item.co2 != null && item.co2 != "")?item.co2:null;
                            break;
                        case "tvoc":
                            spe_val = (item.tvoc != null && item.tvoc != "")?item.tvoc:null;
                            break;
                        case "no2":
                            spe_val = (item.no2 != null && item.no2 != "")?item.no2:null;
                            break;
                        case "o3":
                            spe_val = (item.o3 != null && item.o3 != "")?item.o3:null;
                            break;
                        case "temp":
                            spe_val = (item.temp != null && item.temp != "")?item.temp:null;
                            break;
                        case "hum":
                            spe_val = (item.hum != null && item.hum != "")?item.hum:null;
                            break;
                    }



                    points1.features.push({
                        geometry: {
                            //coordinates: [item.lng, item.lat],
                            coordinates: [parseFloat(item.lon), parseFloat(item.lat)],
                            type: 'Point'
                        },
                        properties: {
                            value: spe_val
                        },
                        type: 'Feature'
                    })
                });


                console.log(points1);

                
                //计算克里金等值面

                let val_num = (hl_val == "pm25")?0:(hl_val == "temp")?1:
                (hl_val == "tvoc")?2:(hl_val == "o3")?3:(hl_val == "no2")?4:
                (hl_val == "co")?5:(hl_val == "pm10")?6:0;


                    levelV = level_group[val_num];
                    colors = color_group[val_num];

                    let kriging_contours = kriging.getVectorContour(points1, 'value', {
                        model: 'exponential',
                        sigma2: 0,
                        alpha: 100
                    }, levelV, dd1);
                    L.geoJson(boundaries, { style: polystyle }).addTo(intersectionLayer); //行政區邊界
                    /* 行政區填色 */

                    
                    //按照面积对图层进行排序，规避turf的一个bug
                    kriging_contours.features.sort(sortArea)

                    //后面使用要求输入的参数为Feature<Polygon> ，而turf.isobands的是 MultiPolygon，需要先 flatten() 处理一下,同时去掉图形为空的记录
                    boundaries = turf.flatten(boundaries); //行政边界

                    kriging_contours = turf.flatten(kriging_contours); //等值面边界
                    //console.log('kriging_contours:' + JSON.stringify(kriging_contours));


                    //根据行政边界裁剪图形,
                    //现在放大一些区域边界还是没有充满  后面可以封装成一个插件，在源码里面进行canvas  clip 剪辑只显示的geojson区域，其余的栅格部分不显示
                    let features = []; //裁剪后的结果集
                    kriging_contours.features.forEach(function (feature1) {
                        boundaries.features.forEach(function (feature2) {
                            let intersection_b = null;
                            try {

                                intersection_b = turf.intersect(feature1, feature2);

                            } catch (e) {

                                try {
                                    //色斑图绘制之后，可能会生成一些非法 Polygon
                                    //解决的方法通常就是做一次 turf.buffer() 操作，这样可以把一些小的碎片 Polygon 清理掉。
                                    feature1 = turf.buffer(feature1, 0);
                                    intersection_b = turf.intersect(feature1, feature2);
                                } catch (e) {

                                    intersection_b = feature1; //实在裁剪不了就不裁剪了,根据业务需求自行决定

                                }

                            }
                            if (intersection_b != null) {
                                intersection_b.properties = feature1.properties;
                                intersection_b.id = (Math.random() * 100000).toFixed(0);
                                features.push(intersection_b);
                            }
                        });
                    });

                    intersection = turf.featureCollection(features);
                    if(intersection == null || intersection.length <=0 || typeof(intersection) == "undefined"){
                        alert("無克利金值");
                    }else{
                        L.geoJSON(intersection, {
                            style: function (feature) {
                                console.log(feature.properties.value);
                                console.log(hotColor(feature.properties.value));
                                return {

                                    fillColor: hotColor(feature.properties.value),
                                    weight: 0,
                                    fillOpacity: 0.3,

                                };
                            }
                        }).addTo(intersectionLayer); 
                    }
                
                intersectionLayer.addTo(map);
            }else{
                intersection = null;
                map.removeLayer(intersectionLayer);
            }

            /*if(this.isKir){
                //map.removeLayer(intersectionLayer);
                intersectionLayer.addTo(map);
            }else{
                //remove
                map.removeLayer(intersectionLayer);
                //markerlayerClusterGroup.addTo(map);
            }*/

            

        }
    },
}
/* 行政區填色 */
function polystyle(feature) {
    return {
        fillColor: '#ffdc84',
        weight: 2,
        opacity: 0.3,
        color: 'transparent',  //Outline color
        fillOpacity:0.3
    };
}
function hotColor(d) {

    let index = levelV.findIndex((item) => item >= d);
    if (index > -1) {
        return colors[index].fill
    } else {
        return colors[colors.length - 1].fill
    }
}
function sortArea(a, b) {
    return turf.area(b) - turf.area(a);
}

function markerLayerData(first_data){
        
    var pm25_val = first_data.pm25;
    var temperature_val = (first_data.temp !== null && first_data.temp !=="")?first_data.temp:"NaN";
    var tvocc_val = (first_data.tvoc !== null && first_data.tvoc !=="")?first_data.tvoc:"NaN";
    var pm10_val = (first_data.pm10 !== null && first_data.pm10 !=="")?first_data.pm10:"NaN";
    var co_tp_val = (first_data.co !== null && first_data.co !=="")?first_data.co:"NaN";
    var co2_tp_val = (first_data.co2 !== null && first_data.co2 !=="")?first_data.co2:"NaN";
    var hum_tp_val = (first_data.hum !== null && first_data.hum !=="")?first_data.hum:"NaN";
    var nh3_tp_val = (first_data.nh3 !== null && first_data.nh3 !=="")?first_data.nh3:"NaN";
    var h2s_tp_val = (first_data.h2s !== null && first_data.h2s !=="")?first_data.h2s:"NaN";
    var o3_tp_val = (first_data.o3 !== null && first_data.o3 !=="")?first_data.o3:"NaN";
    var no2_tp_val = (first_data.no2 !== null && first_data.no2 !=="")?first_data.no2:"NaN";

    
    if(pm25_val !== "NaN"){
        pmtwo_val = pm25_val;
        pmtwo_color = chkPm_val(pm25_val);
        $(".pmtwo_item .dot").attr("class","dot " + pmtwo_color);
        $(".pmtwo_item .cont").attr("class","cont " + pmtwo_color);
        $(".pmtwo_item .cont").text(pmtwo_val);
    }
    
    if(pm10_val !== "NaN"){
        pmten_val = pm10_val;
        pmten_color = chkPm10_val(pm10_val);
        $(".pmten_item .dot").attr("class","dot " + pmten_color);
        $(".pmten_item .cont").attr("class","cont " + pmten_color);
        $(".pmten_item .cont").text(pmten_val);
    }
    
    if(temperature_val !== "NaN"){
        temp_val = temperature_val;
        temp_color = chkTemp_val(temp_val);
        $(".temp_item .dot").attr("class","dot " + temp_color);
        $(".temp_item .cont").attr("class","cont " + temp_color);
        $(".temp_item .cont").text(temp_val);
    }
    
    if(tvocc_val !== "NaN"){
        tvoc_val = tvocc_val;
        tvoc_color = chkTvoc_val(tvoc_val);
        $(".tvoc_item .dot").attr("class","dot " + tvoc_color);
        $(".tvoc_item .cont").attr("class","cont " + tvoc_color);
        $(".tvoc_item .cont").text(tvoc_val);
    }

    if(co_tp_val !== "NaN"){
        co_val = co_tp_val;
        co_color = chkCO_val(co_val);
        $(".co_item .dot").attr("class","dot " + co_color);
        $(".co_item .cont").attr("class","cont " + co_color);
        $(".co_item .cont").text(co_val);
    }

    if(co2_tp_val !== "NaN"){
        co2_val = co2_tp_val;
        co2_color = chkTvoc_val(co2_val);
        $(".co2_item .dot").attr("class","dot " + co2_color);
        $(".co2_item .cont").attr("class","cont " + co2_color);
        $(".co2_item .cont").text(co2_val);
    }

    if(hum_tp_val !== "NaN"){
        humi_val = hum_tp_val;
        humi_color = chkTvoc_val(humi_val);
        $(".humi_item .dot").attr("class","dot " + humi_color);
        $(".humi_item .cont").attr("class","cont " + humi_color);
        $(".humi_item .cont").text(humi_val);
    }

    if(nh3_tp_val !== "NaN"){
        nh3_val = nh3_tp_val;
        nh3_color = chkTvoc_val(nh3_val);
        $(".nh3_item .dot").attr("class","dot " + nh3_color);
        $(".nh3_item .cont").attr("class","cont " + nh3_color);
        $(".nh3_item .cont").text(nh3_val);
    }

    if(h2s_tp_val !== "NaN"){
        h2s_val = h2s_tp_val;
        h2s_color = chkTvoc_val(h2s_val);
        $(".h2s_item .dot").attr("class","dot " + h2s_color);
        $(".h2s_item .cont").attr("class","cont " + h2s_color);
        $(".h2s_item .cont").text(h2s_val);
    }

    if(o3_tp_val !== "NaN"){
        o3_val = o3_tp_val;
        o3_color = chkO3_val(o3_val);
        $(".o3_item .dot").attr("class","dot " + o3_color);
        $(".o3_item .cont").attr("class","cont " + o3_color);
        $(".o3_item .cont").text(o3_val);
    }

    if(no2_tp_val !== "NaN"){
        no2_val = no2_tp_val;
        no2_color = chkNO2_val(no2_val);
        $(".no2_item .dot").attr("class","dot " + no2_color);
        $(".no2_item .cont").attr("class","cont " + no2_color);
        $(".no2_item .cont").text(no2_val);
    }

    $(".address-area").text(first_data.area);
    $(".address-type").text(first_data.areatype);
    $(".address-town").text(first_data.town);
    $(".update-time").text(formatDatetime(first_data.time));

    if(!$(".map-area .map-info").hasClass("open")){
        $(".map-area .map-info").addClass("open");
    }
}

function markerAdd(Layer){
    
    
}

</script>


